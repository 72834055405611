<template>
  <div>
    <!--  checkStrictly <a-tree :loadData="onLoadData" @click="onSelect" :treeData="treeData" /> -->
    <a-button @click="createFile('68721da518f5d7d8f44551ab2d42f500')"
      >测试创建文件夹</a-button
    >
    <a-tree
      checkable
      :treeData="treeData"
      @select="this.onSelect"
      @check="this.onCheck"
    />
  </div>
</template>
<script>
const user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'treecx',
  data () {
    return {
      // treeData: [],
      // userInfo:{},
      // pos:[],
      replaceFields: {
        children: 'children',
        title: 'title'
      },

      //  expandedKeys: ['0-0-0', '0-0-1'],
      // autoExpandParent: true,
      // checkedKeys: ['0-0-0'],
      // selectedKeys: [],
      treeData: [
        {
          title: '张晨成',
          key: '0-0',
          children: [
            { title: 'leaf', key: '0-0-0' },
            {
              title: 'parent-0',
              key: '0-0-1',
              children: [
                { title: 'leaf-0-0', key: '0-0-0-0' },
                { title: 'leaf-0-1', key: '0-0-0-1' }
              ]
            }
          ]
        },
        {
          title: 'parent 1-1',
          key: '0-1',
          children: [
            {
              key: '0-1-0',
              title: 'zcvc',
              children: [
                { title: 'leaf-1-0', key: '0-0-1-0' },
                { title: 'leaf-1-1', key: '0-0-1-1' }
              ]
            }
          ]
        }
      ]
    }
  },
  watch: {
    checkedKeys (val) {
      console.log('onCheck', val)
    }
  },
  created () {
    //  this.ajaxFn(0);
    // this.onLoadData(0);

    this.$axios.get(8003, { Pid: 0 }, res => {
      console.log(res.data.list)
      if (res.data.list) {
        this.treeData = res.data.list
      } else {
        this.$message.error('获取数据失败!')
      }
    })
  },
  methods: {
    onCheck (checkedKeys) {
      console.log('onCheck', checkedKeys)

      this.checkedKeys = checkedKeys
    },
    onSelect (selectedKeys, info) {
      console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    }
    //  createFile(id){
    //     this.$axios.post(9984, { UserId: user.uid, Pid: id, IsPrivate: 0, Name: 'cexing_test' },res=>{
    //        console.log(res)
    //       if (res.data.code == 1) {
    //         // that.ajaxFn_list(id, that.indexTab - 1, 0); ///刷新
    //         // that.ajaxFn(that.indexTab - 1, 0);
    //         // that.visible1 = false;
    //         this.onLoadData(id);
    //       } else {

    //       }
    //     });
    //  },

    //     onSelect(selectedKeys, info) {
    //         console.log(
    //           "selected",
    //           selectedKeys,
    //           info,
    //           info.title,
    //           info.key,
    //           info.pos
    //         );
    //     },
    //      onLoadData(treeNode) {
    //        console.log(treeNode)
    //        let that=this;
    //       return new Promise(resolve => {
    //         // if(treeNode==0){

    //         // }else if(!treeNode.dataRef){

    //         // }else if (treeNode.dataRef.children) {
    //         //   resolve();
    //         //   return;
    //         // }

    //         let id;
    //         if(treeNode==0){
    //             id=0;
    //            that.treeData=[];
    //         }else if(!treeNode.dataRef){
    //             id = treeNode;
    //         }else{
    //           this.pos = treeNode.pos.split('-');
    //           console.log(this.pos)
    //           let str = treeNode.dataRef.key;
    //           if(str.indexOf('-')>0){
    //             let index =str.indexOf('-');
    //               console.log(str.slice(0,index),index)
    //               id= str.slice(0,index);
    //           }else{
    //             id=treeNode.dataRef.key;
    //           }
    //         }
    //         that.$axios.post(9974,{ UserId: user.uid, Pid: id, IsPrivate:0},res=>{
    //           console.log(res);

    //           if(res.data.code!=1){that.$message.error(res.data.msg); return false;}
    //           if(treeNode==0){
    //             this.treeData = res.data.data;
    //           }else if(!treeNode.dataRef&&treeNode!=0){
    //             let arrpos = this.pos;
    //             //如在最顶层添加文件夹或改名字 就直接刷新
    //             if(arrpos.length==1){ this.onLoadData(0);}
    //             if(arrpos.length==2){//如在第一层添加文件夹或改名字
    //               this.treeData[arrpos[1]].children = res.data.data;
    //             }
    //             //如在第一层一下添加文件夹或改名字
    //             if(arrpos.length>2){
    //                   let treeArr = this.treeData[arrpos[1]];
    //                   let lit;
    //                   console.log(treeArr)
    //                   for(let i=2;i<arrpos.length;i++){
    //                     console.log(treeArr)
    //                     treeArr = treeArr.children[arrpos[i]];
    //                   }
    //                   console.log(treeArr)
    //                   treeArr.children=res.data.data;
    //                   console.log(treeArr)
    //             }
    //              console.log(123)
    //              //0-8     this.treeData[8].children
    //              // 0-8-0  this.treeData[8].children[0].children
    //              //0-8-0-2 this.treeData[8].children[0].children[2].children
    //             //  console.log(this.treeData[8],this.treeData[8].children,this.treeData[8].children[0],this.treeData[8].children[0].children)
    //           // this.treeData[8].children[0].children = res.data.data;
    //           console.log(this.treeData)
    //           }else{
    //             treeNode.dataRef.children = res.data.data;

    //           }
    //           this.treeData = [...this.treeData];
    //           console.log(this.treeData)
    //           resolve();
    //         })
    //       });
    //     },
    //     ajaxFn(treeNode){
    //       let that=this;
    //       return new Promise(resolve => {
    //         // if (treeNode.dataRef.children) {
    //         //   resolve();
    //         //   return;
    //         // }
    //         // let id;
    //         // if(treeNode==0){
    //         //     id=0;
    //         // }else{
    //         //   let str = treeNode.dataRef.key;
    //         //   if(str.indexOf('-')>0){
    //         //     let index =str.indexOf('-');
    //         //       console.log(str.slice(0,index),index)
    //         //   }else{
    //         //     id=treeNode.dataRef.key;
    //         //   }
    //         // }
    //         // that.$axios.post(9999,{ UserId: user.uid, Pid: id, IsPrivate: 0 },res=>{
    //         setTimeout(() => {
    //             treeNode.dataRef.children = [
    //               { title: 'Child Node', key: `${treeNode.eventKey}-0` },
    //               { title: 'Child Node', key: `${treeNode.eventKey}-1` },
    //             ];
    //             that.treeData = [...that.treeData];
    //             resolve();
    //           // });
    //        },10000)
    //       })
    //     }
  }
}
</script>
<style scoped lang="less"></style>
